<template>
  <div class="content">
    <div class="top-nav">
      <span class="btn" @click="getHome">&lt;</span>
      <span>内容详情</span>
      <span></span>
    </div>
    <p class="top-tilte">[文案编辑]线上文案编辑，100/天</p>
    <div>
      <div class="title">招聘岗位</div>
      <p class="psd">线上文案编辑</p>
    </div>
    <div>
      <div class="title">工作内容</div>
      <p class="psd">根据提供的资料撰写文章</p>
    </div>
    <div>
      <div class="title">福利待遇</div>
      <p class="psd">工作时间地点不限，根据文字字符数核算</p>
      <img src="@/assets/images/wenan.jpg" alt="" class="pic" />
    </div>
    <div>
      <div class="title">扫描上方二维码参与报名：</div>
      <p class="msg">
        【岗位仅限本科及以上学历报名，所有报名均免费，每日报名人数较多，报名后请勿删除，避免收不到录取信息】
      </p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const getHome = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
.content {
  font-size: 18px;
  .top-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 5px 20px 5px;
    .btn {
      padding-left: 20px;
      font-weight: lighter;
    }
  }
  .top-tilte {
    font-weight: bolder;
    padding: 10px;
    line-height: 30px;
  }
  .title {
    margin: 20px 5px;
    color: #b02418;
  }
  .psd {
    padding: 10px;
  }
  .works span {
    padding: 0 10px;
    display: block;
    line-height: 40px;
  }
  .pic {
    margin: 0 5vw;
    width: 90vw;
    height: 380px;
  }
  .msg {
    padding: 0 10px;
    display: block;
    line-height: 30px;
  }
}
</style>
